<template>
  <!-- counter -->
  <div>
    <v-container grid-list-lg class="carder" fluid>
      <v-row>
        <v-col cols="3">
          <v-text-field
            outlined
            dense
            rounded
            class="ma-3"
            color="blue"
            label="Search"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="5"></v-col>
        <v-col cols="2">
          <v-select
            :items="items"
            label="Filter By"
            class="ma-3"
            color="light-blue"
            dense
            rounded
            outlined
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-dialog transition="dialog-top-transition" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey"
                class="ma-3 pa-5"
                v-bind="attrs"
                v-on="on"
                dense
                rounded
              >
                <v-icon>mdi-plus</v-icon>
                <span>New</span>
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="orange" dark>New User</v-toolbar>
                <v-card-text>
                  <v-container grid-list-lg>
                    <v-row>
                      <v-col>
                        <v-text-field
                          label="Name"
                          dense
                          outlined
                          color="info"
                        ></v-text-field>
                        <v-text-field
                          label="Contact No"
                          dense
                          outlined
                          color="info"
                        ></v-text-field>
                        <v-select
                          :items="getSystem"
                          label="System"
                          color="light-blue"
                          dense
                          outlined
                        ></v-select>
                        <v-select
                          :items="getRole"
                          label="Role"
                          color="light-blue"
                          dense
                          outlined
                        ></v-select>
                        <v-select
                          :items="status"
                          label="Status"
                          color="light-blue"
                          dense
                          outlined
                        ></v-select>
                        <p class="font-weight-bold">Expire by</p>
                        <v-date-picker
                          v-model="user.date"
                          :landscape="true"
                          color="info"
                        ></v-date-picker>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">Close</v-btn>
                  <v-btn text @click="dialog.value = false">Save</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </v-col>
      </v-row>
      <v-simple-table class="table">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Expire By</th>
              <th class="text-left">Contact No</th>
              <th class="text-left">System</th>
              <th class="text-left">Role</th>
              <th class="text-left">Status</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in desserts" :key="i">
              <td>
                <v-avatar
                  size="54"
                  color="light-blue darken-1 white--text"
                  class="my-2"
                >
                  <img
                    :src="require(`../../../assets/users/users-1.svg`)"
                    :alt="item.name.charAt(0)"
                  />
                </v-avatar>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="my-auto ml-2" v-bind="attrs" v-on="on">{{
                      item.name
                    }}</span>
                  </template>
                  <span>{{ item.name }}</span>
                </v-tooltip>
              </td>
              <td>
                <span>{{ item.expire_by || "-" }}</span>
              </td>
              <td>{{ item.contact_no || "-" }}</td>
              <td>{{ item.system || "GetOrders" }}</td>
              <td>
                <v-chip class="ma-2" :color="returnIcon('', 'Admin')" outlined>
                  <v-icon left>{{ returnIcon("icon", "Admin") }}</v-icon>
                  {{ "Admin" }}
                </v-chip>
              </td>
              <td>
                <v-icon
                  :color="
                    item.status == 'Ongoing'
                      ? 'green'
                      : item.status == 'Expired'
                      ? 'red'
                      : 'grey'
                  "
                  >mdi-circle-medium</v-icon
                ><span
                  :class="
                    item.status == 'Ongoing'
                      ? 'font-weight-bold green--text'
                      : item.status == 'Expired'
                      ? 'font-weight-bold red--text'
                      : 'font-weight-bold grey--text'
                  "
                  >{{ item.status || "Inactive" }}</span
                >
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="blue" v-bind="attrs" v-on="on">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="error" v-bind="attrs" v-on="on">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Remove</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
    <div class="mt-6">
      <div class="text-center">
        <v-pagination
          v-model="page"
          color="blue darken"
          :length="6"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Users",

  data() {
    return {
      page: 1,
      user: {
        name: "",
        system: "",
        phone: "",
        role: "",
        status: "",
        date: "",
      },
      items: ["Name", "Expire By", "Contact no", "System", "Status"],
      status: ["Ongoing", "Expired", "Inactive"],
      roles: [
        { name: "Super Admin", icon: "mdi-server", color: "success" },
        { name: "Admin", icon: "mdi-broom", color: "blue lighten-2" },
        {
          name: "Viewer",
          icon: "mdi-sunglasses",
          color: "deep-purple accent-1",
        },
      ],
      systems: [
        { name: "getOrders", color: "red darken-1 white--text" },
        { name: "Uniplacement", color: "purple darken-1 white--text" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      desserts: "getUserData",
    }),
    getRole() {
      return this.roles.map((d) => d.name);
    },
    getSystem() {
      return this.systems.map((d) => d.name);
    },
  },
  beforeMount() {
    this.$store.dispatch("startLoad");
    this.$store.dispatch("fetchAccounts");
  },
  methods: {
    returnIcon(ident, value) {
      //   console.log(this.roles);
      if (ident == "icon") {
        const icon_name = this.roles.find((item) => item.name == value).icon;
        return icon_name;
      } else {
        const icon_name = this.roles.find((item) => item.name == value).color;
        return icon_name;
      }
    },
    returnSystem(value) {
      //   console.log(this.roles);
      return this.systems.find((item) => item.name == value).color;
    },
    pic() {
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

.pad {
  margin-top: 3px;
}

.w-75 {
  width: 75%;
}
.circle {
  height: 5%;
  width: 5%;
  border: 1px solid black;
  background-color: black;
  border-radius: 25%;
}
</style>
